import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import ActiveFilters from './ActiveFilters'
import MobileFilterList from './MobileFilterList'
import {
  useTranslation,
  useOutsideClick,
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  aggregationsSortingToRender,
} from '../../../../utils'
import { Button } from '../../..'
import SubCategoriesMobile from '../../ProductList/SubCategoriesMobile'

export default function MobileFilter({
  aggregations = {},
  totalProductCount = 0,
  numberOfActiveFilters = 0,
  isMobileFilterVisible = false,
  hideMobileFilter,
  submitForms,
  resetAllFilters,
  subCategories,
  categoryTitle,
  categoryUrls,
  searchData,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  ref = useRef(),
}) {
  const [visibleFilter, setVisibleFilter] = useState(null)
  const { t } = useTranslation()

  const classes = classNames('mobile-filter', {
    'mobile-filter--visible': isMobileFilterVisible,
  })

  useOutsideClick(ref, () => {
    if (isMobileFilterVisible) {
      hideMobileFilter()
    }
  })

  // Make the overlay re-appear on mount and disappear on unmount, when it
  // previously was active as well. When resizing the window to desktop and
  // the mobile view was active, the desktop filter doesn't show an overlay,
  // but when switching back to mobile the visible flag will still be true,
  // so the overlay should re-appear along with the open filter panel.
  useEffect(() => {
    if (isMobileFilterVisible) {
      dispatchShowOverlayEvent()
    }
    return () => {
      if (isMobileFilterVisible) {
        dispatchHideOverlayEvent()
      }
    }
  })

  const sortedAggregationsToRender = aggregationsSortingToRender(aggregations)

  return (
    <>
      <form className={classes} ref={ref}>
        <div className="mobile-filter__header">
          <span>{t('FILTER_HEADING')}</span>
          {numberOfActiveFilters > 0 && (
            <Button variant="secondary" size="sm" onClick={resetAllFilters}>
              {t('FILTER_RESET_ALL')}
            </Button>
          )}
        </div>
        <div className="mobile-filter__wrap">
          {subCategories.length > 0 && (
            <SubCategoriesMobile
              categoryTitle={categoryTitle}
              subCategories={subCategories}
            />
          )}
          {sortedAggregationsToRender.map((aggregation) => {
            const { key: id, title, min, max } = aggregation

            return (
              <div key={id} className="mobile-filter__section">
                <button
                  type="button"
                  className="mobile-filter__button reset-btn filter-option-track"
                  title={title}
                  onClick={() => setVisibleFilter(id)}
                >
                  {title}
                  {<ActiveFilters {...aggregation} />}
                  <i className="cf-next" />
                </button>

                <MobileFilterList
                  // Care: The order of properties matters here, since the aggregations
                  // have a field 'key' which could override our custom-built key below
                  // which we need for properly updating/reseting the RangeFilter
                  {...aggregation}
                  id={id}
                  title={title}
                  key={max ? `${id}-${min}-${max}` : id}
                  isVisible={visibleFilter == id}
                  closeFilter={() => setVisibleFilter(null)}
                  submitForms={submitForms}
                  categoryUrls={categoryUrls}
                  searchData={searchData}
                />
              </div>
            )
          })}
        </div>
        <div className="mobile-filter__footer text-center p-3">
          <Button variant="primary" onClick={hideMobileFilter}>
            {t('MOBILE_FILTER_CLOSE')(totalProductCount)}
          </Button>
        </div>
      </form>
    </>
  )
}
