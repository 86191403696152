import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from '../../../utils/providers'
import { Button } from '../../index'

export default function FilterResetButton({
  numberOfActiveFilters = 0,
  resetAllFilters,
}) {
  const { t } = useTranslation()

  if (numberOfActiveFilters === 0) {
    return null
  }
  return (
    <Button
      variant="secondary"
      size="sm"
      className="product-list__filter-reset-button ml-auto"
      onClick={resetAllFilters}
    >
      {t('FILTER_RESET_ALL')}
    </Button>
  )
}

FilterResetButton.propTypes = {
  numberOfActiveFilters: PropTypes.number,
  resetAllFilters: PropTypes.func.isRequired,
}
